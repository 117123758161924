import React from "react";
import AboutLayout from "../layouts/AboutLayout";

import SchoolCover from "../assets/photo/about-school-cover.jpg";

import "../scss/pages/About.scss";

const About = () => {
  return (
    <div className="about-main-container">
      <div className="about-school-cover">
        <img src={SchoolCover} alt="" />
      </div>
      <AboutLayout />
    </div>
  );
};

export default About;
