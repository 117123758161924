import React from "react";

import "../scss/components/Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-copyright">
        <span>&copy;</span>Shillong Ri-Lum Presbytery English School
      </div>
    </div>
  );
};

export default Footer;
