import React from "react";
import { AcademicsLayout } from "../layouts/AcademicsLayout";

const Academics = () => {
  return (
    <div>
      <AcademicsLayout />
    </div>
  );
};

export default Academics;
