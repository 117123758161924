import React from "react";

import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Academics from "../pages/Academics";
import About from "../pages/About";
import ContactUs from "../pages/ContactUs";
import Gallery from "../pages/Gallery";
import Faculties from "../pages/Faculties";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/academics" element={<Academics />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/faculties" element={<Faculties />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </>
  );
};

export default Router;
