import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import "../scss/components/MobileNavBar.scss";

const MobileNavBar = () => {
  const [isToggled, setIsToggle] = useState(false);

  const toggle = () => {
    setIsToggle(!isToggled);
  };

  return (
    <div className="mobile-navbar-container">
      {isToggled ? (
        <div className="close-icon" onClick={toggle}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
      ) : (
        <div className="menu-icon">
          <FontAwesomeIcon icon={faBars} onClick={toggle} />
        </div>
      )}
      <div
        className={isToggled ? "mobile-navbar-visible" : "mobile-navbar-hide"}
      >
        <NavLink to={"/"} activeclassname="active">
          <div className="mobile-navbar-links">Home</div>
        </NavLink>
        <NavLink to={"/about"} activeclassname="active">
          <div className="mobile-navbar-links">About</div>
        </NavLink>
        <NavLink to={"/gallery"} activeclassname="active">
          <div className="mobile-navbar-links">Gallery</div>
        </NavLink>
        <NavLink to={"/academics"} activeclassname="active">
          <div className="mobile-navbar-links">Academics</div>
        </NavLink>
        <NavLink to={"/faculties"} activeclassname="active">
          <div className="mobile-navbar-links">Faculties</div>
        </NavLink>
        <NavLink to={"/contactus"} activeclassname="active">
          <div className="mobile-navbar-links">Contact Us</div>
        </NavLink>
      </div>
    </div>
  );
};

export default MobileNavBar;
