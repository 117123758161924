import React from "react";

import "../scss/layouts/FacultiesLayout.scss";

import FacultyOne from "../assets/faculties/faculty-1.jpg";
import FacultyTwo from "../assets/faculties/faculty-2.jpg";
import FacultyThree from "../assets/faculties/faculty-3.jpg";
import FacultyFour from "../assets/faculties/faculty-4.jpg";
import FacultyFive from "../assets/faculties/faculty-5.jpg";
import FacultySix from "../assets/faculties/faculty-6.jpg";
import FacultySeven from "../assets/faculties/faculty-7.jpg";
import FacultyEight from "../assets/faculties/faculty-8.jpg";
import FacultyNine from "../assets/faculties/faculty-9.jpg";
import FacultyTen from "../assets/faculties/faculty-10.jpg";
import FacultyEleven from "../assets/faculties/faculty-11.jpg";
import FacultyTwelve from "../assets/faculties/faculty-12.jpg";
import FacultyThirteen from "../assets/faculties/faculty-13.jpg";
import FacultyFourteen from "../assets/faculties/faculty-14.jpg";
import FacultyFifteen from "../assets/faculties/faculty-15.jpg";
import FacultySixteen from "../assets/faculties/faculty-16.jpg";

import FacultySeventeen from "../assets/faculties/faculty-17.jpg";
import FacultyEighteen from "../assets/faculties/faculty-18.jpg";
import FacultyOneNine from "../assets/faculties/faculty-19.jpg";
import FacultyTwoZero from "../assets/faculties/faculty-20.jpg";
import FacultyTwoOne from "../assets/faculties/faculty-21.jpg";

import FacultyUser from "../assets/faculties/user-image.webp";

const FacultiesLayout = () => {
  return (
    <div className="faculties-layout-container">
      <div className="faculties-title">
        <h2>Faculties</h2>
      </div>

      <div className="faculties-images-container">
        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyFourteen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Rev. Banteiborlang Deingdoh</h3>
            <p>Principal</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyTen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Jerry Anderson Lyngdoh Nongbri</h3>
            <p>Vice Principal</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyFifteen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Ringkhang Basumatary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyOne} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Ms. Jasmine Basumatary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyTwo} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Uttam Mushahary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyThree} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Pramod Rabha</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyFour} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Johnson Ekka</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyFive} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Pranita Basumatary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultySix} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Priyanka Singh</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultySeven} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Mainao Khaklary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyEight} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Dibekstarning Wanniang</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyNine} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Ms. Manisha Singh</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyEleven} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Ronjali Wary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyTwelve} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Padma Basumatary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyThirteen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Chitra Boro</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultySixteen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Folisha Kharmyndai</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultySeventeen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Ms. Mevoreen Dohling</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyEighteen} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Lyngui Bon Nongrum</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyOneNine} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Femilian Dohtdong</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyTwoZero} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Herambo Basumatary</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyTwoOne} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mr. Najrul Pramanik</h3>
            <p>Asstt. Teacher</p>
          </div>
        </div>

        {/* <div className="faculty-images-details">
          <div className="faculty-image">
            <img src={FacultyUser} alt="" />
          </div>
          <div className="faculty-details">
            <h3>Mrs. Folisha Kharmyndai</h3>
            <p>Asst. Teacher</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FacultiesLayout;
