/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";

import "../scss/layouts/ContactUsLayout.scss";

const ContactUsLayout = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    sendEmail();
    resetForm();
  };

  // console.log(process.env.REACT_APP_PUBLIC_KEY);

  const sendEmail = () => {
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    );
  };

  const resetForm = () => {
    setEmail("");
    setName("");
    setMessage("");
  };

  return (
    <div className="contact-us-layout-container">
      <div className="contact-us-row">
        <div className="contact-address">
          <div className="contact-info">Contact Info</div>
          <div className="email">
            <span>E-mail:</span> shillongschool1@gmail.com
          </div>
          <div className="telephone">
            <span>Telephone Number:</span> 03669299903
          </div>
          <div className="mobile">
            <span>Mobile Number:</span> +916002141384, +917002959518
          </div>

          <div className="school-address">
            <div className="address">
              <span>Address:</span> Nepalpara, P.O: Gossaigaon
            </div>
            <div className="state-address">
              <span>District:</span> Kokrajhar BTR Assam
            </div>
            <div className="pin-code">
              <span>Pin:</span> 783360
            </div>
          </div>
        </div>

        <div className="contact-form-container">
          <div className="contact-us-form-title">Reach Us</div>

          <div className="form-area-inputs-fields">
            <form ref={form} onSubmit={onSubmit} className="form-container">
              <div className="form-names-input">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-email">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-message">
                <label htmlFor="message">Message</label>
                <textarea
                  type="text"
                  rows={6}
                  cols={30}
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="form-btn">
                <input type="submit" value="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="google-map">
        <div className="school-map-title">
          <h2>Map</h2>
        </div>
        <div className="school-google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.8828572867433!2d89.97358531538588!3d26.459504683324898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e27fa4140d11c3%3A0xb54a2ccb733bbc89!2sShillong%20Ri-Lum%20Presbytery%20English%20School!5e0!3m2!1sen!2sin!4v1670926969813!5m2!1sen!2sin"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUsLayout;
