import React from "react";

import SchoolLogo from "../assets/logo/school-logo-1.jpg";

import SchoolCover from "../assets/photo/cover.jpg";

import SchoolBanner from "../assets/logo/school-banner.jpg";

import "../scss/components/Logo.scss";

const Logo = () => {
  return (
    <div className="school-logo-container">
      <img src={SchoolBanner} alt="" />
    </div>
  );
};

export default Logo;
