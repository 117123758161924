import React from "react";

import "../scss/layouts/AboutLayout.scss";

const AboutLayout = () => {
  return (
    <div className="about-layout-container">
      <div className="about-our-school">
        <h2>About our School</h2>
        <p>
          The Shillong Ri-Lum Presbytery English School, Nepalpara, Gossaigaon
          is a Mission School owned and run by the Shillong Ri-Lum Presbytery,
          Shillong. The School was established in 2006 and it is Co-Educational
          institution having classes Nursery to Class X.
        </p>
      </div>

      <div className="governing-body">
        <h2>Governing Body</h2>
        <p>
          The School is under the management of the Shillong Ri-Lum Presbytery,
          Shillong, Meghalaya.
        </p>
      </div>

      <div className="aims-and-objectives">
        <h2>Aims and Objectives:</h2>
        <ul>
          <li>
            It aims to promote development of Academic Excellence, Discipline,
            Personal Character and high sense of Values among children so as to
            make them good, caring and emphatic citizens of India.
          </li>
          <li>
            To educate children to formulate good Attitude towards
            Socio-Economic, Political and Moral development of the society.
          </li>
          <li>
            To make contribution towards the progress of education and to bring
            about a more inspiring life to all people whom the school can serve.
          </li>
        </ul>
      </div>

      <div className="admission">
        <h2>Admission</h2>

        <p>
          The academic session begins in the Second Week of April every year.
          Application forms are issued in the month of January. Dully completed
          application form should be submitted to the school office on and
          before the prescribed date. Late submission of application form will
          not be entertained.
        </p>
      </div>

      <div className="attendance">
        <h2>Attendance</h2>
        <p>
          Regular attendence is vital to academic success. Hence a student is
          required to attend minimum of 80% of school days in an Academic
          Session. A student who fails to meet the minimum requirement is liable
          to be detained. Inability to attend school due to illnes or unforseen
          events may be considered by the school authority.
        </p>
      </div>
    </div>
  );
};

export default AboutLayout;
