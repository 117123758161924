import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import One from "../assets/gallery/caurosel-1.jpg";
import Two from "../assets/gallery/caurosel-2.jpg";
import Three from "../assets/gallery/caurosel-3.jpg";
import Four from "../assets/gallery/caurosel-4.jpg";
import Five from "../assets/gallery/caurosel-5.jpg";

import "../scss/components/ImageSlider.scss";

const ImageSlider = () => {
  const setting = {
    slidesToShow: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    fade: true,
    dots: true,
    slidesToScroll: 1,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    autoplay: true,
    appendDots: (dots) => <ul className="slick-custom-dots">{dots}</ul>,
    customPaging: (i) => (
      <div className="slick-slider-custom-dots">
        <div className="custom-dots"></div>
      </div>
    )
  };

  return (
    <div className="image-slider">
      <Slider {...setting}>
        <div className="image-container">
          <img src={One} alt="" />
        </div>
        <div className="image-container">
          <img src={Two} alt="" />
        </div>
        <div className="image-container">
          <img src={Three} alt="" />
        </div>
        <div className="image-container">
          <img src={Four} alt="" />
        </div>
        <div className="image-container">
          <img src={Five} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
