import React from "react";

import { BrowserRouter as Routers } from "react-router-dom";

import Router from "./common/Router";

import "./App.scss";
import Logo from "./components/Logo";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="app">
      <Routers>
        <Logo />
        <NavBar />
        <Router />
        <Footer />
      </Routers>
    </div>
  );
};

export default App;
