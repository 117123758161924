import React from "react";
import FacultiesLayout from "../layouts/FacultiesLayout";

const Faculties = () => {
  return (
    <div>
      <FacultiesLayout />
    </div>
  );
};

export default Faculties;
