import React from "react";

import "../scss/layouts/AcademicsLayout.scss";

export const AcademicsLayout = () => {
  return (
    <div className="academics-layout-container">
      <div className="academics">
        <h2>Academics</h2>
        <p>
          The Shillong Ri-Lum Presbytery English School, Nepalpara, Gossaigaon
          is a Mission School owned and run by the Shillong Ri-Lum Presbytery,
          Shillong. The School was established in 2006 and it is Co-Educational
          institution having classes Nursery to Class X.
        </p>
      </div>

      <div className="attendance">
        <h2>Attendance</h2>
        <p>
          Regular attendance is vital to academic success. Hence a student is
          required to attend minimum of 80% of school days in an Academic
          Session. A student who fails to meet the minimum requirement is liable
          to be detained. Inability to attend school due to illness or
          unforeseen events may be considered by the school authority.{" "}
        </p>
      </div>

      <div className="uniforms">
        <h2>Uniforms:</h2>
        <p>
          The school uniform is the only dress permitted in the school. All
          students should use only the school uniform while coming to school.
          Wearing of accessories in the school is prohibited.
        </p>
      </div>

      <div className="school-hours">
        <h2>School Hours</h2>
        <div className="winter-timing">
          <table>
            <thead>
              <tr>
                <th colSpan={3}>Winter Timing</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Nursery KG-I & KG-II</td>
                <td>09:00 a.m.</td>
                <td>11:30 a.m.</td>
              </tr>

              <tr>
                <td>Class : I to X</td>
                <td>08:45 a.m.</td>
                <td>02.15 p.m.</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="summer-timing">
          <table>
            <thead>
              <tr>
                <th colSpan={3}>Summer Timing</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Nursery KG-I & KG-II</td>
                <td>08:00 a.m.</td>
                <td>10:45 a.m.</td>
              </tr>

              <tr>
                <td>Class : I to X</td>
                <td>08:00 a.m.</td>
                <td>01.15 p.m.</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="office-hours">
          <table>
            <thead>
              <tr>
                <th colSpan={3}>Office Hours</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>For Students</td>
                <td>09:00 a.m.</td>
                <td>11:30 a.m.</td>
              </tr>

              <tr>
                <td>For Parents/Guardian</td>
                <td>08:45 a.m.</td>
                <td>02.15 p.m.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
