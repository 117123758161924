import React from "react";

import "../scss/layouts/GalleryLayout.scss";

import One from "../assets/gallery/gallery-1.1.jpg";
import Two from "../assets/gallery/gallery-2.jpg";
import Three from "../assets/gallery/gallery-3.jpg";
import Four from "../assets/gallery/gallery-4.jpg";
import Five from "../assets/gallery/gallery-5.jpg";
import Six from "../assets/gallery/gallery-6.jpg";
import Seven from "../assets/gallery/gallery-7.jpg";
import Eight from "../assets/gallery/gallery-8.jpg";
import Nine from "../assets/gallery/gallery-9.jpg";
import Ten from "../assets/gallery/gallery-10.jpg";
import Eleven from "../assets/gallery/gallery-11.jpg";
import Twelve from "../assets/gallery/gallery-12.jpg";
import OneThree from "../assets/gallery/gallery-13.jpg";
import OneFour from "../assets/gallery/gallery-14.jpg";
import OneFive from "../assets/gallery/gallery-15.jpg";
import OneSix from "../assets/gallery/gallery-16.jpg";
import OneSeven from "../assets/gallery/gallery-17.jpg";
import OneEight from "../assets/gallery/gallery-18.jpg";
import OneNine from "../assets/gallery/gallery-19.jpg";
import TwoZero from "../assets/gallery/gallery-20.jpg";
import TwoOne from "../assets/gallery/gallery-21.jpg";
import TwoTwo from "../assets/gallery/non-teaching.jpg";

const GalleryLayout = () => {
  return (
    <div className="gallery-layout-container">
      <div className="gallery-title">
        <h2>Gallery</h2>
      </div>

      <div className="gallery-image-container">
        <div className="gallery-image">
          <img src={One} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Two} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Three} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Four} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Five} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Six} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Seven} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Eight} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Nine} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Ten} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Eleven} alt="" />
        </div>
        <div className="gallery-image">
          <img src={Twelve} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneThree} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneFour} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneFive} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneSix} alt="" />
        </div>
        <div className="gallery-image">
          <img src={TwoOne} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneEight} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneSeven} alt="" />
        </div>
        <div className="gallery-image">
          <img src={OneNine} alt="" />
        </div>
        <div className="gallery-image">
          <img src={TwoZero} alt="" />
        </div>
        <div className="gallery-image">
          <img src={TwoTwo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default GalleryLayout;
