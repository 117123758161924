/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import "../scss/layouts/HomeLayout.scss";

import ImageSlider from "../components/ImageSlider";

import Princ from "../assets/photo/principal.jpg";

const HomeLayout = () => {
  return (
    <div className="home-container">
      <ImageSlider />

      <div className="home-body">
        <div className="home-detail">
          <h2>SHILLONG RI-LUM PRESBYTERY ENG. SCHOOL</h2>
          <p>
            Shillong Ri-Lum Presbytery English Mission School is a
            Co-Educational Institution located in gossaigaon, Assam. This
            Institution was established in the year of 2006 having the Moto of
            "Light, "Wisdom" and "Truth". The Shillong Ri-Lum Presbytery School
            is now providing excellent education amongst the people in the heart
            of gossaigaon Sub-division.
          </p>
          <p>
            On the 23rd January,2006, the Foundation Stone for the construction
            of the Mission Centre was laid down in the presence of the
            representatives and Officials of the Shillong Ri-Lum Presbytery and
            the Mission worker of the KJP Synod Sepngi and a group of 40 local
            villagers.
          </p>
          <p>
            On 4th March, 2006, the Presbytery opened the Nepalpara Mission by
            starting an English School christened as "The Shillong Ri-Lum
            Presbyterian English Mission School" along with the Mission Center
            by the then Moderator Rev. A.S Mylliemngap in the presence of the
            then Executive Secretary, KJP Synod Sepngi, members of the standing
            committee, Shillong Ri-Lum Presbytery and members of the Lutheran
            Church. The Shillong Ri-Lum Presbyterian English Mission School
            begins its journey, tha is to provide the people of Nepalpara with
            total and proper education they see and receive glorious Light of
            Life.
          </p>
        </div>

        <div className="home-news">
          <div className="news-title">
            <h2>News & Events</h2>
          </div>

          <div className="home-news-info">
            <marquee
              behavior="scroll"
              direction="up"
              height="400"
              scrollamount="4"
            >
              <div className="news">
                <p>
                  <FontAwesomeIcon icon={faCircle} />
                  <span>New</span>
                  <a href="#">
                    New Academic session starts from the 1st April, 2023
                  </a>
                </p>
              </div>
              <div className="news">
                <p>
                  <FontAwesomeIcon icon={faCircle} />
                  <span>New</span>
                  <a href="#">
                    "Dear pupils, All the best for Final Examination 2022-2023"
                  </a>
                  {/* <a href="https://animepic.s3.ap-south-1.amazonaws.com/Pre-Christmas-1.pdf">
                    Pre-Christmas celebration on 20th Dec.
                  </a> */}
                </p>
              </div>
              {/* <div className="news">
                <p>
                  <FontAwesomeIcon icon={faCircle} />
                  <span>New</span>
                  <a href="https://animepic.s3.ap-south-1.amazonaws.com/Felicitation22_105300-1.pdf">
                    Conducting Felicitation program on 21st Dec.
                  </a>
                </p>
              </div> */}
            </marquee>
          </div>
        </div>
      </div>

      <div className="home-list">
        <div className="home-thing-to-know">
          <h3>Things to know about Our School</h3>

          <ul>
            <li>Academic year starts in the month of April</li>
            <li>
              Prospectus & Admission form can be collected from the month of
              January
            </li>
            <li>There is Entrance Exam for new students from class I to VII</li>
            <li>
              Selection process is purely via personal interview based on
              Entrance Exam's result
            </li>
          </ul>
        </div>

        <div className="home-key-feature">
          <h3>Key Features of the School</h3>
          <ul>
            <li>Highly Experienced Faculty</li>
            <li>Smart Digital Classes</li>
            <li>Frequent Career Guidance Workshop </li>
            <li>Special Attention & Remedial Classes to Weak Students</li>
            <li>Excellent Academic Results </li>
          </ul>
        </div>
      </div>

      <div className="home-pri-container">
        <div className="princ-image-container">
          <div className="princ-img">
            <img src={Princ} alt="" />
          </div>

          <div className="princ-details">
            <div className="principal-name">Rev. Banteiborlang Diengdoh</div>
            <div className="principal-title">Principal</div>
            <div className="principal-school">
              Shillong Ri-Lum Presbytery English School
            </div>
          </div>
        </div>

        <div className="princ-quote">
          <h2>Dear Parents/ Guardians</h2>
          <div className="quote-1">
            <span></span>
            Choosing the right school for your child vitally important. Most
            parents want an excellent education for their childern but they also
            want them to be safe and happy. At Shillong Ri-Lum Presbytery
            English Mission School we strife hard to bring quality education
            emphasizing on all round development of the students. We value all
            children in our care and believe their time in our school will be
            fun, rewarding and fulfilling.
          </div>
          <div className="quote-2">
            <span></span>I feel privilege to welcome you and your children to
            our school and looking forward for building your children up.
          </div>
          <div className="quote-3">"TO GOD BE THE GLORY!"</div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
