import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "../scss/components/NavBar.scss";
import MobileNavBar from "./MobileNavBar";

const NavBar = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const detectSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenWidth]);

  return (
    <div className="navbar-container">
      {screenWidth > 768 ? (
        <div className="desktop-navbar">
          <NavLink to={"/"} activeclassname="active">
            <div className="navbar-links">Home</div>
          </NavLink>
          <NavLink to={"/about"} activeclassname="active">
            <div className="navbar-links">About</div>
          </NavLink>
          <NavLink to={"/gallery"} activeclassname="active">
            <div className="navbar-links">Gallery</div>
          </NavLink>
          <NavLink to={"/academics"} activeclassname="active">
            <div className="navbar-links">Academics</div>
          </NavLink>
          <NavLink to={"/faculties"} activeclassname="active">
            <div className="navbar-links">Faculties</div>
          </NavLink>
          <NavLink to={"/contactus"} activeclassname="active">
            <div className="navbar-links">Contact Us</div>
          </NavLink>
        </div>
      ) : (
        <MobileNavBar />
      )}
    </div>
  );
};

export default NavBar;
