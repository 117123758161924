import React from "react";
import ContactUsLayout from "../layouts/ContactUsLayout";

const ContactUs = () => {
  return (
    <div>
      <ContactUsLayout />
    </div>
  );
};

export default ContactUs;
