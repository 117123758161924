import React from "react";

import GalleryImg from "../assets/gallery/gallery-1.jpg";
import HomeLayout from "../layouts/HomeLayout";

import "../scss/pages/Home.scss";

const Home = () => {
  return (
    <div className="home">
      <HomeLayout />
    </div>
  );
};

export default Home;
