import React from "react";
import GalleryLayout from "../layouts/GalleryLayout";

const Gallery = () => {
  return (
    <div>
      <GalleryLayout />
    </div>
  );
};

export default Gallery;
